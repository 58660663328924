import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import API_URL from '../pages/config/api'; // Assurez-vous que l'URL de l'API est correcte
import Footer from '../components/Footer';
import Header from '../components/Header';
import ticket from '../assets/images/ticket.png';
import start from '../assets/images/start.png';

const ResultPage = () => {
    const location = useLocation();
    const { searchQuery } = location.state || {}; // Récupère le paramètre de recherche depuis la navigation
    const [events, setEvents] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (searchQuery) {
            fetchEvents(searchQuery);
        }
    }, [searchQuery]);

    const fetchEvents = async (query) => {
        try {
            const response = await fetch(`${API_URL}/api/index?search=${query}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Une erreur est survenue lors de la récupération des données.');
            }

            const data = await response.json();
            setEvents(data.events); // Stocker les événements reçus dans l'état
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <>
            <Header />
            <div className="container mt-4">
                <h5 className='text-center'>Résultats des événements pour "{searchQuery}"</h5>
                {error && <p style={{ color: 'red' }}>{error}</p>}

                {events.length > 0 ? (
                    <div className="row">
                        {events.map((event) => (
                            <div className="col-md-4 mb-4" key={event.id}>
                                <Link to={`/detail-event/${event.id}`} className="text-decoration-none text-dark">
                                    <div className="card h-100">
                                        <div className="position-relative">
                                            <img
                                                src={`${API_URL}/${event.image1_url}`}
                                                height="250px"
                                                className="card-img-top"
                                                alt={event.title}
                                            />
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <p className="card-text">{event.date}</p>
                                                        <h5 className="card-title" style={{ color: '#4539B4' }}>
                                                            {new Date(event.start_date).toLocaleString('default', { month: 'short' }).toUpperCase()}
                                                        </h5>
                                                        <p className="card-text">
                                                            {new Date(event.start_date).getDate()}/{new Date(event.start_date).getFullYear()}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <h5 className="card-title" style={{ color: '#2D2C3C' }}>
                                                            {event.title.slice(0, 14)}{event.title.length > 14 ? '...' : ''}
                                                        </h5>
                                                        <p className="card-text">{event.location}</p>
                                                        <p className="card-text">
                                                            {new Date(`1970-01-01T${event.start_time}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} -
                                                            {new Date(`1970-01-01T${event.end_time}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                        </p>
                                                        <p className="card-text">
                                                            <img src={ticket} height="20" width="20" alt="Ticket" /> {event.price} FCFA -
                                                            <img src={start} height="20" width="20" alt="Start" style={{ color: '#4539B4' }} /> {event.interested} intéressé(s)
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>Aucun événement trouvé.</p>
                )}

            </div>
            <div className='container-fluid' style={{ background: 'rgba(0, 0, 0, 1)' }}>

                <Footer />
            </div>
        </>
    );
};

export default ResultPage;
