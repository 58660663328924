import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SearchForm = () => {
    const [searchParams, setSearchParams] = useState({ search: '' });
    const navigate = useNavigate();

    // Gestion du changement des paramètres de recherche
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSearchParams(prevParams => ({
            ...prevParams,
            [name]: value,
        }));
    };

    // Soumission du formulaire pour filtrer les événements
    const handleSubmit = (e) => {
        e.preventDefault();

        if (searchParams.search) {
            // Redirige vers la page des résultats avec les paramètres de recherche
            navigate('/result-page', { state: { searchQuery: searchParams.search } });
        }
    };


    return (
        <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center' }}>
            <form onSubmit={handleSubmit} className="d-flex w-100 justify-content-center" role="search" style={{ maxWidth: '900px' }}>

                <div className="input-group" style={{ width: '75%' }}>
                    <input
                        className="form-control"
                        type="search"
                        placeholder="Rechercher des événements, des catégories, des emplacements ..."
                        name="search"
                        value={searchParams.search}
                        onChange={handleInputChange}
                        style={{ borderRadius: '0px', outline: 'none', boxShadow: 'none' }}
                    />
                    <button className="input-group-text bg-white" type="submit" style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}>
                        <i className="fa fa-search"></i>
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SearchForm;
